<template>
  <v-container>
    <v-row
      v-if="isInternalUser"
      class="justify-center align-self-start"
    >
      <v-col class="col-md-8 col-sm-12">
        <v-card class="py-9 px-12">
          <v-card-title
            class="primary--text headline"
          >
            Backlog
          </v-card-title>

          <v-card-subtitle class="subtitle-1">
            What's Coming to iAdOps?
          </v-card-subtitle>

          <v-card-text>
            <v-divider />

            <v-row class="align-center">
              <v-col>
                <v-btn
                  block
                  color="coolgray"
                  class="py-5"
                  outlined
                  @click="toggleDetails"
                >
                  Toggle Details
                </v-btn>
              </v-col>

              <v-col class="my-4">
                <v-select
                  v-model="sortedByProperty"
                  dense
                  hide-details
                  :items="sortOptions"
                  label="Sort"
                  outlined
                />
              </v-col>

              <v-col class="my-4">
                <v-select
                  v-model="filteredByStatus"
                  clearable
                  dense
                  hide-details
                  :items="statusOptions"
                  label="Status"
                  outlined
                />
              </v-col>
            </v-row>

            <v-divider />

            <v-row class="mt-3">
              <v-col v-if="proposedBacklogItems.length">
                <backlog-item
                  v-for="(backlogItem, backlogItemId) in proposedBacklogItems"
                  :key="backlogItemId"
                  :backlog-item="backlogItem"
                  :show-all-details="showAllDetails"
                />
              </v-col>

              <v-col v-else>
                No Results
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col class="col-md-4 col-sm-12">
        <v-card class="pa-3">
          <v-card-subtitle
            class="midgray--text text-uppercase font-weight-bold"
          >
            Favorite Features
          </v-card-subtitle>

          <v-card-text>
            <v-row>
              <v-col class="px-0">
                <feature-card
                  v-for="(featureItem, featureId) in topFeatures"
                  :key="featureId"
                  :feature-item="featureItem"
                  :show-all-details="true"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="pa-3 mt-3">
          <v-card-subtitle
            class="midgray--text text-uppercase font-weight-bold"
          >
            Top Data Collections
          </v-card-subtitle>

          <v-card-text>
            <v-row>
              <v-col class="px-0">
                <feature-card
                  v-for="(featureItem, featureId) in topData"
                  :key="featureId"
                  :feature-item="featureItem"
                  :show-all-details="true"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="pa-3 mt-3">
          <v-card-subtitle
            class="midgray--text text-uppercase font-weight-bold"
          >
            Participants
          </v-card-subtitle>

          <v-card-text>
            <v-row>
              <v-col class="px-0">
                <user-card
                  v-for="(participant, participantId) in participants"
                  :key="participantId"
                  :selected="filteredByVoter === participant.email"
                  :user="participant"
                  class="cursor-pointer"
                  @filteredByVoter="setFilteredByVoter"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row
      v-else
      class="body-2"
    >
      Nothing to see here.
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import _ from 'underscore'

import BacklogItem from '../components/BacklogItem'
import FeatureCard from '../components/FeatureCard'
import UserCard from '../components/UserCard'

const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth')
const { mapState } = createNamespacedHelpers('docs')

export default {
  name: 'Backlogs',
  components: { BacklogItem, FeatureCard, UserCard },
  data() {
    return {
      showAllDetails: false,
      filteredByStatus: null,
      filteredByVoter: null,
      sortedByProperty: 'totalWeight',
      sortedByDirection: 'DSC',
      sortOptions: [
        {
          text: 'Weight',
          value: 'totalWeight',
        },
        {
          text: 'Votes',
          value: 'totalVotes',
        },
      ],
      statusOptions: [
        // {
        //   text: 'Blocked',
        //   value: 'blocked',
        // },
        {
          text: 'No Status',
          value: 'none',
        },
        {
          text: 'Pending',
          value: 'pending',
        },
        {
          text: 'In Progress',
          value: 'in progress',
        },
        {
          text: 'Ready',
          value: 'ready',
        },
      ],
    }
  },
  computed: {
    ...mapAuthGetters(['isInternalUser']),
    ...mapState({
      backlog: (state) => state.backlog,
    }),
    dataBacklogItems() {
      const { backlog } = this
      const { data } = backlog

      return this.getBacklogItems(data)
    },
    featureBacklogItems() {
      const { backlog } = this
      const { features } = backlog

      return this.getBacklogItems(features)
    },
    proposedBacklogItems() {
      const { backlog } = this
      const { proposed } = backlog

      return this.getBacklogItems(proposed)
    },
    topData() {
      const listLength = 5
      return this.dataBacklogItems.slice(0, listLength)
    },
    topFeatures() {
      const listLength = 5
      return this.featureBacklogItems.slice(0, listLength)
    },
    participants() {
      const { dataBacklogItems, featureBacklogItems, proposedBacklogItems } = this
      const endpoints = [
        {
          endpointName: 'data',
          items: dataBacklogItems,
        },
        {
          endpointName: 'features',
          items: featureBacklogItems,
        },
        {
          endpointName: 'proposed',
          items: proposedBacklogItems,
        },
      ]

      const voters = endpoints.reduce((participants, endpoint) => {
        const { endpointName, items } = endpoint

        items.forEach((item) => {
          const { key, votes = {} } = item
          const voterEmails = Object.keys(votes)

          voterEmails.forEach((email) => {
            const vote = votes[email]

            if (!participants[email]) participants[email] = []

            participants[email].push({ endpointName, key, ...vote })
          })
        })

        return participants
      }, {})

      return Object.keys(voters).map((email) => {
        const votes = voters[email]
        return { email, votes }
      })
    },
  },
  methods: {
    totalWeight(backlogItem) {
      const { votes = {} } = backlogItem

      return Object.keys(votes).reduce((total, email) => {
        const vote = votes[email]

        total += vote.weight

        return total
      }, 0)
    },
    toggleDetails() {
      this.showAllDetails = !this.showAllDetails
    },
    filterByStatus(backlogItem) {
      return backlogItem.status === this.filteredByStatus
    },
    filterByVoter(backlogItem) {
      const { votes = {} } = backlogItem

      return votes[this.filteredByVoter]
    },
    setFilteredByVoter(voterEmail) {
      this.filteredByVoter = voterEmail
    },
    getBacklogItems(backlogItems) {
      const {
        filteredByStatus,
        filteredByVoter,
        sortedByDirection,
        sortedByProperty,
      } = this
      const items = Object.keys(backlogItems).map((key) => {
        const backlogItem = backlogItems[key]
        const { votes = {} } = backlogItem
        const totalWeight = this.totalWeight(backlogItem)
        const totalVotes = Object.keys(votes).length
        const avgWeight = totalWeight / totalVotes

        return { ...backlogItem, key, avgWeight, totalVotes, totalWeight }
      })
      const filters = [
        {
          fnFilter: this.filterByStatus,
          value: filteredByStatus,
        },
        {
          value: filteredByVoter,
          fnFilter: this.filterByVoter,
        },
      ]

      const filteredItems = filters.reduce((filteredSet, filter) => {
        const { fnFilter, value } = filter

        if (value) {
          return filteredSet.filter(fnFilter)
        }

        return filteredSet
      }, items)

      const sortedFilteredItems = sortedByDirection === 'ASC'
        ? _.sortAscByCaseInsensitive(filteredItems, sortedByProperty)
        : _.sortDscByCaseInsensitive(filteredItems, sortedByProperty)

      return sortedFilteredItems
    },
  },
}
</script>
