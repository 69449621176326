<template>
  <v-card
    class="elevation-0 mt-0"
  >
    <v-card-title
      :class="`darkblue--text subtitle-2 ${showAllDetails ? 'pb-0 px-0' : 'px-0'}`"
    >
      <span
        class="cursor-pointer"
        @click="toggleTheseDetails"
      >
        {{ backlogItem.name }}
      </span>

      <v-chip
        :color="statusColor.color"
        class="ml-3"
        :dark="statusColor.dark"
        small
      >
        {{ backlogItem.status || 'none' }}
      </v-chip>
    </v-card-title>

    <v-card-text
      v-if="showTheseDetails"
      class="px-0"
    >
      <v-row>
        <v-col class="pb-0">
          <span class="coolgray--text">
            Score
          </span>

          <span class="px-1 py-0 font-weight-medium nearblack--text">
            {{ backlogItem.totalWeight }}
          </span>

          <span class="px-1 coolgray--text">
            |
          </span>

          <span class="px-1 py-0 font-weight-medium nearblack--text">
            {{ backlogItem.totalVotes }}
          </span>

          <span class="coolgray--text">
            Votes
          </span>
        </v-col>
      </v-row>

      <v-row
        class="body-2"
      >
        <v-col>
          <span class="coolgray--text">
            Participants
          </span>

          <span class="px-1 py-0 font-weight-medium nearblack--text">
            {{ voterEmails }}
          </span>
        </v-col>
      </v-row>

      <v-row
        v-if="backlogItem.description"
        class="body-2"
      >
        <v-col class="pt-0">
          <span class="coolgray--text">
            Description
          </span>

          <span class="px-1 py-0 font-weight-medium nearblack--text">
            {{ backlogItem.description }}
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'BacklogItem',
  props: {
    backlogItem: {
      type: Object,
      default: () => ({}),
    },
    showAllDetails: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDetails: false,
    }
  },
  computed: {
    showTheseDetails() {
      return this.showAllDetails || this.showDetails
    },
    statusColor() {
      const colorMap = {
        none: { color: 'offwhite', dark: false },
        pending: { color: 'coolgray', dark: true },
        'in progress': { color: 'bluegreen', dark: true },
        ready: { color: 'primary', dark: true },
      }

      return colorMap[this.backlogItem.status || 'none']
    },
    voterEmails() {
      const { backlogItem } = this
      const { votes } = backlogItem

      return Object.keys(votes || {}).map((email) => email).join(', ')
    },
  },
  methods: {
    toggleTheseDetails() {
      this.showDetails = !this.showTheseDetails
    },
  },
}
</script>
