<template>
  <v-card
    class="mt-0 elevation-0"
  >
    <v-card-title
      :class="`coolgray--text body-2 font-weight-${selected ? 'bold' : 'medium'} py-0`"
      @click="filterByVoter"
    >
      {{ user.email }}
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  name: 'UserCard',
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    filterByVoter() {
      const value = this.selected ? null : this.user.email

      this.$emit('filteredByVoter', value)
    },
  },
}
</script>
