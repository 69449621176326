<template>
  <v-card
    class="mt-0 elevation-0"
  >
    <v-card-title
      class="coolgray--text body-2 font-weight-medium py-0"
    >
      {{ featureItem.name }}
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  name: 'FeatureCard',
  props: {
    featureItem: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
